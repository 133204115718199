import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import {Link} from "react-router-dom";

const ValidacaoCertificadoErro: React.FC = () => {
    return (
        <>
            <Header/>

            <section id="validar" className="erro clearfix">
                <div className="container">

                    <div className="row d-flex justify-content-between align-items-center">
                        <div className="col-12 col-md-8 mx-auto">

                            <div className="card-cod-erro">
                                <div className="content">
                                    <div className="codigo-invalido">
                                        <div className="area-texto">
                                            <h4 className="text-center text-danger">Código Inválido</h4>
                                            <p className="text-center">
                                                Este código <b>não é compatível</b> com produto Original Polo
                                                Lentes! <br/> <br/>
                                                Por favor tente outro código!
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <Link to="/" className="btn btn-scanner">validar novo código</Link>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </section>

            <Footer/>
        </>
    );
};

export default ValidacaoCertificadoErro;
