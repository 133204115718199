/* eslint-disable */
import React from 'react';
import SectionOticas from "../components/SectionOticas";
import SectionParcerias from "../components/SectionParceria";
import SectionContato from "../components/SectionContato";
import Footer from "../components/Footer";
import Header from "../components/Header";
import SectionValidar from "../components/SectionValidar";

const Home: React.FC = () => {
    return (
        <>
            <Header/>
            <SectionValidar/>
            <main id="main">
                {/*<SectionOticas />*/}
                <SectionParcerias/>
                <SectionContato/>
            </main>
            <Footer/>
        </>
    );
};

export default Home;
