import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";

import Home from "./Home";
import ValidacaoCertificadoErro from "./ValidacaoCertificadoErro";
import ValidacaoCertificado from "./ValidacaoCertificado";

const RouteApp: React.FC = () => {
    console.log('Garantia V1.1');
    return (
        <Router>
            {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
            <Switch>
                <Route path="/certificado/:id">
                    <ValidacaoCertificado/>
                </Route>
                <Route path="/error">
                    <ValidacaoCertificadoErro/>
                </Route>
                <Route path="/">
                    <Home/>
                </Route>
            </Switch>
        </Router>
    );
};

export default RouteApp;
