import React from "react";

const SectionParcerias: React.FC = () => {
    return (
        <section id="parceiro" className="wow fadeIn">
            <div className="container">
                <header className="section-header">
                    <h3>Quero ser um Parceiro da Polo Lentes</h3>
                    <p>Entre em contato conosco através de um dos números abaixo, e saiba mais sobre como se
                        tornar um
                        parceiro!</p>
                </header>

                <div className="row row-eq-height justify-content-center">
                    <div className="col-lg-4 mb-4">
                        <div className="card wow fadeInUp" data-wow-delay="0.2s">
                            <img src="/img/telephone.png" alt="Polo lentes" className="mx-auto py-3"/>
                            <div className="card-body">
                                <h5 className="card-title">00 0000 0000</h5>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 mb-4">
                        <div className="card wow fadeInUp" data-wow-delay="0.2s">
                            <img src="/img/telephone.png" alt="Polo lentes" className="mx-auto py-3"/>
                            <div className="card-body">
                                <h5 className="card-title">00 0000 0000</h5>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    );
};

export default SectionParcerias;
