import React from "react";

const Header: React.FC = () => {
    return (
        <header id="header" className="fixed-top">
            <div className="container">

                <div className="logo float-left">
                    <a href="/" className="scrollto">
                        <img src="/img/polo-lentes.png"
                             alt="Polo Lentes - Valide sua lente"
                             className="img-fluid"/>
                    </a>
                </div>

                <nav className="main-nav float-right d-none d-lg-block">
                    <ul>
                        <li className="active"><a href="/#validar">Validar lente</a></li>
                        {/*<li><a href="#oticas">Óticas</a></li>*/}
                        <li><a href="/#parceiro">Torne-se um Parceiro</a></li>
                        <li><a href="/#contato">Contato</a></li>
                    </ul>
                </nav>
            </div>
        </header>
    );
};

export default Header;
